import React,{useState, useEffect} from 'react'
import { useParams } from 'react-router-dom';
import MembershipPoster from '../assets/Membership.png';
import { Link } from 'react-router-dom';
import bgVideo from '../assets/background.mp4';
import { EventCard } from '../components';
import { SponsorCard } from '../components/Sponsors';

export const HomePage = () => {
  const { eventYear, eventId } = useParams();
  const [event, setEvent] = useState(null);
  const [eventsData, setEventsData] = useState({ events: {}, committee: {} });
  const [sponsors, setSponsors] = useState([]);
  

  useEffect(() => {
    fetch('/data/db.json')
      .then(response => response.json())
      .then(data => {
        setEventsData(data.data);
        if (data?.data?.events?.[eventYear]) {
          const yearData = data.data.events[eventYear];
          const foundEvent = yearData.find(event => event.id === eventId);
          setEvent(foundEvent || null);
        }
        if (data?.data?.sponsors) {
          setSponsors(data.data.sponsors); // Add sponsors to state
        }
      })
      .catch(error => console.error("Error fetching data:", error));
  }, [eventYear, eventId]);

  const yearKeys = Object.keys(eventsData.events);
  const key = yearKeys[1];
  const eventsForCurrentYear = eventsData.events[key] || [];
  const yearHighlightKeys = Object.keys(eventsData.committee);
  const keyHighlight = yearHighlightKeys[0] || ""; 
  const higlightForCurrentYear = eventsData.committee[keyHighlight] || []; 
  

  const maxLength = 100; 
  const truncateDescription = (description) => {
    if (!description) return '';
    if (description.length <= maxLength) return description;
    return description.substring(0, maxLength) + '...';
  };
  
  return (
    <div className="">
      <div className="relative w-full h-[75vh] overflow-hidden">
        <video className="absolute top-0 left-0 w-full h-full object-cover z-0" autoPlay loop muted>
          <source src={bgVideo} type="video/mp4" />
        </video>
    {/* Dark Overlay */}
      <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50 z-10"></div>

        <div className="relative z-10 container mx-auto w-full sm:px-6 lg:px-8 overflow-hidden">
          <div className="mt-20 flex flex-col items-center justify-center py-4">
            <div className="relative text-center">
              <div className="relative text-center text-white">
              <div 
                className="text-lg sm:text-xl absolute -top-12 left-1/2 transform -translate-x-1/2 bg-white text-gray-700 px-6 sm:px-8 py-3 sm:py-4 rounded-full shadow-md"
              > 
                One of the 
                </div>
                <h1
                    className="md:mb-4 text-xl sm:text-xl md:text-[2.5rem] font-normal pt-4 sm:pt-8 px-4 sm:px-0"
                    data-aos="fade"
                    data-aos-duration="1000"
                    data-aos-delay="500"
                >
                Leading Platform for{" "}
                <span
                  className="text-[#DB7E05] font-bold"
                  style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.8)" }}
                >
                  Student
                </span>
                </h1>
                <h1
                  className="text-xl sm:text-2xl md:text-[2.5rem] font-normal md:px-4"
                  data-aos="fade"
                  data-aos-duration="1000"
                  data-aos-delay="1000"
                >
                  Innovation and Activities
                </h1>
              </div>
              <div
               className="mt-4 sm:mt-4 mx-8 sm:mx-16 lg:mx-48 text-white flex flex-col gap-2 text-xs sm:text-sm lg:text-base font-normal"
                data-aos="fade"
                data-aos-duration="1000"
                data-aos-delay="1500"
                style={{ textShadow: "3px 3px 2px rgba(0, 0, 0, 0.8)" }}
              >
                Our mission is to foster academic growth, skill development, and career advancement for all students across various disciplines.
              </div>
              <button
                className="mt-4 sm:mt-8 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                data-aos="fade"
                data-aos-duration="1000"
                data-aos-delay="1500"
                type="button"
                >
                <Link to="/events">Explore Events <span className="ml-2 text-2xl">→</span> </Link>
                </button>
              </div>
            </div>
          </div>
        </div>
        
        <div className="relative z-10 -mt-28" 
          data-aos="fade-up"
          data-aos-duration="800"
          data-aos-delay="1000">
        <div className="py-10 md:pb-20" >
          <div className="py-10 px-10 flex flex-col gap-4 gap-y-20 md:gap-8 lg:gap-16 text-black bg-lt-blue dark:bg-gray-800 rounded-lg shadow-xl mx-4 sm:mx-8 lg:mx-16">
            <div className="grid grid-cols-7 gap-8">
              <div className="col-span-7 flex flex-col gap-8 md:col-span-4 lg:col-span-4">
                <div className="flex items-center">
                  <svg width="48" height="12" viewBox="0 0 48 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.226497 6L6 11.7735L11.7735 6L6 0.226497L0.226497 6ZM46.05 7C46.6023 7 47.05 6.55228 47.05 6C47.05 5.44772 46.6023 5 46.05 5V7ZM6 7H46.05V5H6V7Z" fill="#FFA2A2"/>
                  </svg>
                  <p className="text-xl font-bold capitalize tracking-wide mx-4 text-white">
                    Membership Drive
                  </p>
                  <svg width="47" height="12" viewBox="0 0 47 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M46.8485 6L41.075 0.226497L35.3014 6L41.075 11.7735L46.8485 6ZM1.02498 5C0.472698 5 0.0249825 5.44772 0.0249825 6C0.0249825 6.55228 0.472698 7 1.02498 7V5ZM41.075 5L1.02498 5V7L41.075 7V5Z" fill="#FFA2A2"/>
                  </svg>
                </div>
                <div className="flex flex-col gap-2 font-work-sans text-white">
                  CSI-WIET opens doors to a world of learning and connections. Dive into exclusive webinars, workshops, and the industrial visits. Hone your coding skills with Coders Club and connect with fellow tech enthusiasts. Join a vibrant community, unlock exclusive events, and network with the best. Become a member, ignite your passion, and take your tech journey to the next level! Register today!
                </div>
                <div>
                  <div className="border-1 inline-block rounded border py-4 px-6 text-black bg-white dark:bg-white dark:border-black dark:border-opacity-10">
                    <p className="text-black dark:text-black">Date: Till 30th of April, 2024</p>
                  </div>
                  <div className="py-2"></div>
                  
                </div>
              </div>
              <div className="relative col-span-7 md:col-span-3 lg:col-span-2 lg:col-start-6">
                <div className="rounded border-black md:border-2">
                  <img src={MembershipPoster} className="bottom-4 -left-4 rounded border-2 border-white dark:border-black md:relative" alt="event poster"/>
                </div>
                <div className="py-2"></div>
                <div className="mt-2 mb-2 rounded-full bg-white py-2 px-8 text-center drop-shadow-xl md:absolute md:my-0 md:left-1/2 md:transform md:-translate-x-1/2 whitespace-nowrap">
                  <Link to="https://tally.so/r/3qPdP8" className="text-black">REGISTER 📌</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-2 px-4  mb-8  md:pt-4 md:pb-2  bg-opacity-50 relative">
      <div className="flex items-center justify-center">
        <svg width="48" height="12" viewBox="0 0 48 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.226497 6L6 11.7735L11.7735 6L6 0.226497L0.226497 6ZM46.05 7C46.6023 7 47.05 6.55228 47.05 6C47.05 5.44772 46.6023 5 46.05 5V7ZM6 7H46.05V5H6V7Z" fill="#FFA2A2"/>
        </svg>
        <p className=" text-dt-blue dark:text-white text-3xl font-semibold capitalize tracking-wide mx-4 text-center">
          Recent Events
        </p>
        <svg width="47" height="12" viewBox="0 0 47 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M46.8485 6L41.075 0.226497L35.3014 6L41.075 11.7735L46.8485 6ZM1.02498 5C0.472698 5 0.0249825 5.44772 0.0249825 6C0.0249825 6.55228 0.472698 7 1.02498 7V5ZM41.075 5L1.02498 5V7L41.075 7V5Z" fill="#FFA2A2"/>
        </svg>
      </div>
      <div className="text-center">
        <p className="mt-2 text-dt-blue dark:text-white text-lg font-semibold capitalize tracking-wide mx-16">
          We have proudly hosted numerous events. Here are some recent events[] organized by CSI!
        </p>
      </div>
      <div class="pt-10 md:pt-8 relative">
    <div class="grid grid-cols-1 md:grid-cols-2 gap-12">
          {eventsForCurrentYear.slice(0, 4).map((event, index) => (
            <EventCard
              key={index}
              title={event.title}
              description={truncateDescription(event.description)}
              date={event.date}
              imageUrl={event.image}
            />
          ))}
          
        </div>
        {eventsForCurrentYear.length > 4 && ( 
        <div className="flex justify-center">
        <button className="mt-2 mb-2 sm:mt-8 text-black font-bold text-xl py-3 px-6 rounded focus:outline-none focus:shadow-outline sm:w-auto flex items-center relative z-10">
          <Link to="/events" className="flex items-center">
            More Events
            <span className="ml-2 text-2xl">↓</span>
          </Link>
        </button>
        
        </div>
        )}
      </div>
      {eventsForCurrentYear.length > 4 && (
           <div class="absolute bottom-0 left-0 right-0 h-2/6 bg-gradient-to-b from-transparent to-gray-200 pointer-events-none "></div> 
        )}
    </div>

    
  <div className="mt-12 mb-12 bg-white dark:bg-gray-800 rounded-lg overflow-hidden p-8">
    <div className="flex items-center justify-center">
      <svg width="91" height="12" viewBox="0 0 91 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.226497 6L6 11.7735L11.7735 6L6 0.226497L0.226497 6ZM91 5H6V7H91V5Z" fill="#FFA2A2" />
      </svg>
      <p className="text-dt-blue dark:text-white text-3xl font-semibold capitalize tracking-wide mx-4 text-center">
        Our Sponsors
      </p>
      <svg width="91" height="12" viewBox="0 0 91 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M90.7735 6L85 0.226497L79.2265 6L85 11.7735L90.7735 6ZM0 7L85 7V5L0 5V7Z" fill="#FFA2A2" />
      </svg>
    </div>
    {/* Centered Sponsor Cards */}
    <div className="mt-8 flex flex-wrap justify-center gap-8">
      {sponsors.map((sponsor) => (
        <SponsorCard key={sponsor.name} name={sponsor.name} logoUrl={sponsor.logoUrl} />
      ))}
    </div>
  </div>


    <div className="w-full -mt-4 "></div>
      <div className="py-4 px-10 md:pt-8 mt-2 ">
        <div className="flex items-center justify-center" >
          <svg width="91" height="12" viewBox="0 0 91 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.226497 6L6 11.7735L11.7735 6L6 0.226497L0.226497 6ZM91 5H6V7H91V5Z" fill="#FFA2A2" />
          </svg>
          <p className="animate-fadeInFromTop text-dt-blue dark:text-white text-3xl font-semibold capitalize tracking-wide mx-4">
            Highlights
          </p>
          <svg width="91" height="12" viewBox="0 0 91 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M90.7735 6L85 0.226497L79.2265 6L85 11.7735L90.7735 6ZM0 7L85 7V5L0 5V7Z" fill="#FFA2A2" />
          </svg>
        </div>
        <div className="text-center">
          <p className="mt-2 text-dt-blue dark:text-white text-lg font-semibold capitalize tracking-wide mx-16">
           
          </p>
        </div>
        <div className="md:pt-8 md:pb-20 flex items-center justify-center" data-aos="fade-up" data-aos-duration="1000">
          {higlightForCurrentYear.length > 0 && higlightForCurrentYear[0].highlight && (
            <img src={higlightForCurrentYear[0].highlight} alt="Highlight" className="w-5/6 h-fit object-cover rounded-lg" />
          )}
        </div>
      </div>

      <div className="p-10 border-dashed border-2 border-dt-blue text-center dark:border-dashed dark:border-2 dark:border-lt-blue" >
        <p className="text-4xl italic font-bold text-opacity-80 dark:text-white">What is <span className="text-lt-blue dark:text-lt-blue">CSI?</span></p>
        <div className="flex flex-col gap-8 italic text-dt-blue dark:text-white" >
          <p className="pt-10">Formed in 1965, the CSI has been instrumental in guiding the Indian IT industry down the right path since its formative years. Today, CSI has 70 chapters all over India, 358 student branches, and more than 90,000 members, including India‚s most famous IT industry leaders, brilliant scientists and dedicated academicians. Now, you have the opportunity to be a part of this distinguished fraternity too. The mission of the CSI is to facilitate research, knowledge sharing, learning and career enhancement for all categories of IT professionals, while simultaneously inspiring and nurturing new entrants into the industry and helping them to integrate into the IT community. The CSI is also working closely with other industry associations, government bodies and academia to ensure that the benefits of IT advancement ultimately percolate down to every single citizen of India.</p>
        </div>
      </div>
      <div className="mt-12 mb-12 flex flex-col lg:flex-row bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden p-4">
        <div className="lg:w-2/3" >
          <iframe width="100%" height="100%" title="map" src="https://maps.google.com/maps?width=100%&amp;height=600&amp;hl=en&amp;q=(Watumull%20Institute%20Of%20Electronic%20Engineering%20And%20Computer%20Technology)&amp;ie=UTF8&amp;t=&amp;z=14&amp;iwloc=B&amp;output=embed" style={{ filter: "grayscale(0.1) contrast(1.2)"}}></iframe>
        </div>
        <hr className="my-4 border-gray-300" />
        <div className="lg:w-1/3 p-4 flex flex-col justify-center">
          <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">Reach Out to Us</h2>
          <p className="text-gray-700 dark:text-gray-400 mb-4">We would love to hear from you!</p>
          <ul className="flex space-x-4 mb-4">
            <li className="flex items-center">
              <Link to="https://www.instagram.com/csi_wiet/?igsh=MWlocGdtZXozcmMyNg%3D%3D" 
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:text-[#E1306C]">
                <svg className="w-6 h-6 text-gray-800 dark:text-white hover:text-current hover:dark:text-current" width="24" height="24" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13 8.83447C10.7062 8.83447 8.83437 10.7063 8.83437 13.0001C8.83437 15.2938 10.7062 17.1657 13 17.1657C15.2937 17.1657 17.1656 15.2938 17.1656 13.0001C17.1656 10.7063 15.2937 8.83447 13 8.83447ZM25.4937 13.0001C25.4937 11.2751 25.5094 9.56572 25.4125 7.84385C25.3156 5.84385 24.8594 4.06885 23.3969 2.60635C21.9312 1.14072 20.1594 0.687599 18.1594 0.590724C16.4344 0.493849 14.725 0.509474 13.0031 0.509474C11.2781 0.509474 9.56875 0.493849 7.84687 0.590724C5.84687 0.687599 4.07187 1.14385 2.60937 2.60635C1.14375 4.07197 0.690621 5.84385 0.593746 7.84385C0.496871 9.56885 0.512496 11.2782 0.512496 13.0001C0.512496 14.722 0.496871 16.4345 0.593746 18.1563C0.690621 20.1563 1.14687 21.9313 2.60937 23.3938C4.075 24.8595 5.84687 25.3126 7.84687 25.4095C9.57187 25.5063 11.2812 25.4907 13.0031 25.4907C14.7281 25.4907 16.4375 25.5063 18.1594 25.4095C20.1594 25.3126 21.9344 24.8563 23.3969 23.3938C24.8625 21.9282 25.3156 20.1563 25.4125 18.1563C25.5125 16.4345 25.4937 14.7251 25.4937 13.0001ZM13 19.4095C9.45312 19.4095 6.59062 16.547 6.59062 13.0001C6.59062 9.45322 9.45312 6.59072 13 6.59072C16.5469 6.59072 19.4094 9.45322 19.4094 13.0001C19.4094 16.547 16.5469 19.4095 13 19.4095ZM19.6719 7.8251C18.8437 7.8251 18.175 7.15635 18.175 6.32822C18.175 5.5001 18.8437 4.83135 19.6719 4.83135C20.5 4.83135 21.1687 5.5001 21.1687 6.32822C21.169 6.52487 21.1304 6.71962 21.0553 6.90135C20.9802 7.08307 20.8699 7.24818 20.7309 7.38723C20.5918 7.52627 20.4267 7.63652 20.245 7.71166C20.0633 7.7868 19.8685 7.82535 19.6719 7.8251Z" fill="currentColor"/>
                </svg>
              </Link>
            </li>
            <li className="flex items-center">
              <Link to="/" className="hover:text-[#1877F2]">
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" className="w-6 h-6 text-gray-800 dark:text-white hover:text-current hover:dark:text-current" width="24" height="24" viewBox="0 0 50 50">
                  <path d="M41,4H9C6.24,4,4,6.24,4,9v32c0,2.76,2.24,5,5,5h32c2.76,0,5-2.24,5-5V9C46,6.24,43.76,4,41,4z M37,19h-2c-2.14,0-3,0.5-3,2 v3h5l-1,5h-4v15h-5V29h-4v-5h4v-3c0-4,2-7,6-7c2.9,0,4,1,4,1V19z" fill="currentColor"></path>
                </svg>
              </Link>
            </li>
            <li className="flex items-center">
              <Link to="/" className="hover:text-[#1DA1F2]">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-gray-800 dark:text-white hover:text-current hover:dark:text-current" width="24" height="24" fillRule="evenodd" clipRule="evenodd" viewBox="0 0 640 640">
                  <path d="M579.999 0H60C27 0 0 27 0 60v520c0 33 27 60 60 60h519.999c33 0 60-27 60-60V60c0-33-27-60-60-60zm-59.942 195.935a161.34 161.34 0 0 1-47.079 12.85c16.914-10.086 29.894-26.22 36.095-45.413-15.862 9.39-33.473 16.287-52.087 19.96-15.048-15.92-36.343-25.83-59.942-25.83-45.331 0-82.04 36.685-82.04 82.016 0 6.355.685 12.626 2.09 18.674-68.186-3.484-128.647-36.13-169.148-85.785a82.08 82.08 0 0 0-11.126 41.245c0 28.465 14.457 53.623 36.485 68.257-13.465-.39-26.068-4.11-37.206-10.205v1.04c0 39.756 28.3 72.969 65.86 80.48a82.817 82.817 0 0 1-21.627 2.883c-5.291 0-10.405-.52-15.472-1.477 10.453 32.587 40.713 56.351 76.642 57-28.134 22.04-63.473 35.163-101.954 35.163-6.65 0-13.134-.39-19.606-1.146 36.39 23.362 79.524 36.85 125.872 36.85 150.935 0 233.507-125.056 233.507-233.494 0-3.603-.06-7.122-.236-10.654 16.027-11.457 29.965-25.937 40.925-42.378l.048-.036z" fill="currentColor"/>
                </svg>
              </Link>
            </li>
            <li className="flex items-center">
              <Link to="/" className="hover:text-[#FF0000]">
                <svg className="w-6 h-6 text-gray-800 dark:text-white hover:text-current hover:dark:text-current" width="24" height="24" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.3253 0.333252C15.0373 0.337252 16.8187 0.354585 18.712 0.430585L19.384 0.459918C21.2893 0.549252 23.1933 0.703919 24.1387 0.966585C25.3987 1.32125 26.388 2.35325 26.7227 3.66259C27.256 5.74259 27.3227 9.79859 27.3307 10.7813L27.332 10.9839V11.2159C27.3227 12.1986 27.256 16.2559 26.7227 18.3346C26.384 19.6479 25.3933 20.6813 24.1387 21.0306C23.1933 21.2933 21.2893 21.4479 19.384 21.5373L18.712 21.5679C16.8187 21.6426 15.0373 21.6613 14.3253 21.6639L14.012 21.6653H13.672C12.1653 21.6559 5.86399 21.5879 3.85866 21.0306C2.59999 20.6759 1.60932 19.6439 1.27466 18.3346C0.741323 16.2546 0.674656 12.1986 0.666656 11.2159V10.7813C0.674656 9.79859 0.741323 5.74125 1.27466 3.66259C1.61332 2.34925 2.60399 1.31592 3.85999 0.967918C5.86399 0.409252 12.1667 0.341252 13.6733 0.333252H14.3253ZM11.332 6.33325V15.6666L19.332 10.9999L11.332 6.33325Z" fill="currentColor"/>
                </svg>
              </Link>
            </li>
          </ul>
          <svg width="419" height="2" viewBox="0 0 419 2" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 1H418.5" stroke="black" strokeOpacity="0.15"/>
          </svg>
          <ul className="space-y-2 mt-4">
            <li className="flex items-center">
              <svg className="w-6 h-6 text-gray-800 dark:text-white mr-2" width="24" height="24" viewBox="0 0 30 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M25 1H5.00001C3.15906 1 1.66667 2.49238 1.66667 4.33333V17.6667C1.66667 19.5076 3.15906 21 5.00001 21H25C26.841 21 28.3333 19.5076 28.3333 17.6667V4.33333C28.3333 2.49238 26.841 1 25 1Z" stroke="currentColor" strokeWidth="2"/>
                <path d="M1.66667 6L13.51 11.9217C13.9727 12.1529 14.4828 12.2732 15 12.2732C15.5172 12.2732 16.0273 12.1529 16.49 11.9217L28.3333 6" stroke="currentColor" strokeWidth="2"/>
              </svg>
              <a href="mailto:principal@watumull.edu" className="text-gray-700 dark:text-gray-300">principal@watumull.edu</a>
            </li>
            <li className="flex items-center">
              <svg className="w-6 h-6 text-gray-800 dark:text-white mr-2" width="24" height="24" viewBox="0 0 31 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M4.23634 1.30311C6.10848 -0.558263 9.19129 -0.227522 10.7589 1.86768L12.7002 4.45823C13.977 6.16269 13.8632 8.54403 12.3479 10.0501L11.9818 10.4162C11.9405 10.5699 11.9363 10.7312 11.9695 10.8869C12.0664 11.5145 12.591 12.8437 14.7877 15.0281C16.9845 17.2125 18.3228 17.7355 18.9597 17.834C19.12 17.8681 19.2861 17.8633 19.4443 17.8201L20.0719 17.1956C21.4195 15.8572 23.487 15.6065 25.1545 16.5126L28.0927 18.1124C30.611 19.4785 31.2463 22.8997 29.185 24.9503L26.999 27.1224C26.3098 27.807 25.3837 28.3777 24.2546 28.4838C21.4702 28.7438 14.9831 28.4115 8.16369 21.6321C1.79963 15.3034 0.578192 9.78392 0.422821 7.06416C0.345904 5.68889 0.995079 4.52591 1.8227 3.70445L4.23634 1.30311ZM8.91286 3.25064C8.13292 2.20919 6.68074 2.12612 5.86235 2.9399L3.44717 5.33969C2.93953 5.84426 2.69647 6.40114 2.72724 6.9334C2.8503 9.09475 3.83483 14.0743 9.79124 19.9969C16.0399 26.2087 21.8102 26.3948 24.0408 26.1856C24.4961 26.144 24.9484 25.9071 25.3714 25.4872L27.5559 23.3135C28.445 22.4305 28.2496 20.8214 26.9913 20.1384L24.0531 18.5401C23.2408 18.1001 22.2902 18.2447 21.6994 18.8324L20.9995 19.5292L20.1842 18.:100 19.7219 20.0195C20.9995 20.8365 20.998 20.838 20.9964 20.838L20.9949 20.8408L20.9903 20.8445L20.9795 20.8527L20.9564 20.8722C20.891 20.9314 20.8211 20.984 20.7472 21.0305C20.6242 21.1096 20.4611 21.196 20.2565 21.268C19.8411 21.4185 19.2904 21.4942 18.6105 21.3833C17.2767 21.1717 15.5092 20.2494 13.1602 17.9013C10.8127 15.5533 9.89585 13.7881 9.68971 12.4603C9.58357 11.7742 9.66818 11.2207 9.82509 10.8005C9.9121 10.5617 10.0357 10.3386 10.1912 10.1399L10.2404 10.0863L10.262 10.0628L10.2712 10.0536L10.2758 10.0485L10.2789 10.0452L10.7219 9.61025C11.3803 8.96395 11.4726 7.88718 10.8527 7.06509L8.91286 4.54829Z" fill="currentColor"/>
              </svg>
              <span className="text-gray-700 dark:text-gray-300">02512567670</span>
            </li>
            <li className="flex items-center">
              <svg className="text-gray-800 dark:text-white mr-2" width="35" height="45" viewBox="0 0 26 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13 1.75C6.78906 1.75 1.75 6.54609 1.75 12.4531C1.75 19.25 9.25 30.0211 12.0039 33.7414C12.1182 33.8985 12.268 34.0262 12.4412 34.1144C12.6143 34.2025 12.8058 34.2484 13 34.2484C13.1942 34.2484 13.3857 34.2025 13.5588 34.1144C13.732 34.0262 13.8818 33.8985 13.9961 33.7414C16.75 30.0227 24.25 19.2555 24.25 12.4531C24.25 6.54609 19.2109 1.75 13 1.75Z" stroke="currentColor" strokeWidth="1.875" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M13 16.75C15.0711 16.75 16.75 15.0711 16.75 13C16.75 10.9289 15.0711 9.25 13 9.25C10.9289 9.25 9.25 10.9289 9.25 13C9.25 15.0711 10.9289 16.75 13 16.75Z" stroke="currentColor" strokeWidth="1.875" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
              <span className="text-gray-700 dark:text-gray-300">
                Plot No.157, C.H.M Campus, Opp. Ulhasnagar Railway Station, Ulhasnagar-421003.
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}



